@mixin page-container {
    max-width: $page-max-width;
    padding-inline: $page-padding-left-mobile $page-padding-right-mobile;
    margin-inline: auto;
}

@mixin default-transition($properties: all, $speed: .3s) {
    transition: $properties $speed ease-in-out;
}

@mixin scrollbar($scrollbar-thumb-color: $color-primary-50, $scrollbar-rail-color: $color-neutral-20 ) {
    $scrollbar-base-color: $color-primary-50;

    $scrollbar-width: 3px;
    $scrollbar-width-moz: thin;
    $scrollbar-border-radius: 0;

    // IE

    .TA {
        scrollbar-base-color: $scrollbar-rail-color;
        scrollbar-face-color: $scrollbar-thumb-color;
    }

    // Chrome & Safari (WebKit)

    &::-webkit-scrollbar {
        background: $scrollbar-rail-color;
        width: $scrollbar-width;
        height: $scrollbar-width;
    }

    &::-webkit-scrollbar-track {
        background: $scrollbar-rail-color;
    }

    &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-color;
        border-radius: $scrollbar-border-radius;
    }

    // Firefox (Gecko)
    scrollbar-color: $scrollbar-thumb-color $scrollbar-rail-color;
    scrollbar-width: $scrollbar-width-moz;
}

@mixin flex($wrap: nowrap, $dir: row, $align: flex-start, $justify: flex-start) {
    display: flex;
    flex-wrap: $wrap;
    flex-direction: $dir;
    align-items: $align;
    justify-content: $justify;
}

@mixin trans($property: all, $duration: .3s, $easing: ease-in-out) {
    transition: $property $duration $easing;
}

@mixin ellipsis($width: 100%) {
    width: $width;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* stylelint-disable declaration-no-important */
@mixin visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}
/* stylelint-enable declaration-no-important */

@mixin headings {
    h1, h2, h3, h4, h5, h6 {
        @content
    }
}

@mixin triangle($direction, $color: currentcolor, $size: 1em) {
    @if not index(top right bottom left, $direction) {
        @error "Direction must be either `top`, `right`, `bottom` or `left`.";
    }

    $direction-map: (
        'top':    'bottom',
        'right':  'left',
        'bottom': 'top',
        'left':   'right',
    );

    width: 0;
    height: 0;
    content: '';
    z-index: 2;
    border-#{map-get($direction-map, $direction)}: ($size * 1.5) solid $color;

    $perpendicular-borders: $size solid transparent;

    @if $direction == top or $direction == bottom {
        border-left: $perpendicular-borders;
        border-right: $perpendicular-borders;
        /* stylelint-disable-next-line at-rule-empty-line-before*/
    } @else if $direction == right or $direction == left {
        border-bottom: $perpendicular-borders;
        border-top: $perpendicular-borders;
    }
}

/*
  @desc Apply attribute after a timeout. Handy only when working with animations and transitions.
  Usage example: &::before { @include triangle(top, $default-primary-base-color, 6px); position: absolute; bottom: 0; }
*/
@mixin applyAttributeAfterDelay($attribute, $startVal, $endVal, $delay) {
    animation-name: applyAttributeFrames;
    animation-delay: #{$delay};
    animation-duration: 1ms;
    animation-fill-mode: forwards;

    @keyframes applyAttributeFrames {
        from {
            #{$attribute}: #{$startVal};
        }

        to {
            #{$attribute}: #{$endVal};
        }
    }
}

@mixin aspect-ratio($ratio, $elem) {
    &::after {
        content: "";
        display: block;
        padding-block-end: $ratio;
    }

    #{$elem} {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
    }
}

