@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviews {
    &-Wrapper {
        @include desktop {
            padding: 0;
        }
    }

    &-Summary {
        @include flex($justify: space-between, $align: center);

        width: 100%;

        @include mobile {
            @include flex($dir: column);
        }

        &Details {
            font-size: $fs-s;
            font-weight: $fw-regular;

            @include mobile {
                margin: 20px 0;
            }

            span {
                font-size: $fs-xxl;
                color: $color-primary;

                &::before {
                    display: none;
                }
            }
        }

        >h2 {
            position: relative;
            background-image: url('src/assets/images/quote.png');
            background-repeat: no-repeat;
            background-position: top right;
            height: 80px;
            min-width: 130px;
            font-size: 30px;
            margin: 0;

            @include flex($align: center);

            @include mobile {
                font-size: $fs-l;
            }
        }
    }

    & &-Button {
        position: relative;
        margin: 0;
    }

    &-Popup {
        @include mobile {
            inset-block-start: 0;
            height: 100%;
        }

        .Popup {
            &-Header {
                @include mobile {
                    @include flex($align: center, $justify: space-between);
                }
            }

            &-Heading {
                @include mobile {
                    font-weight: $fw-light;
                    font-size: $fs-m;
                }
            }

            &-CloseBtn {
                @include mobile {
                    height: 30px;
                }
            }
        }
    }

    &-LoadMore {
        width: 100%;

        @include flex($justify: center);

        button {
            margin: 0 auto;
        }
    }
}
