@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviewItem {
    border: none;

    @include desktop {
        @include flex($dir: column);
    }

    &-RatingSummary {
        width: 75%;
        border-bottom: 1px solid $gray-light;
        padding-block-end: 10px;
        margin-block-end: 10px;

        &Item {
            .ProductReviewRating {
                margin-inline-start: 0;
            }
        }
    }

    &-ReviewDetails {
        font-size: $fs-xs;

        .ShowMoreLessText-ToggleLink {
            font-weight: $fw-semi-bold;
        }
    }

    &-ReviewAuthor {
        span {
            font-weight: $fw-semi-bold;
        }
    }
}
